// @flow

import React, { useState, useCallback, useRef, useLayoutEffect } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import './AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import Tooltip from '@mui/material/Tooltip';
import useResizeObserver from '@react-hook/resize-observer';
import CircularProgress from '@mui/material/CircularProgress';
import { IconButton, Grid, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.js`;

const useWidth = (target: any) => {
  const [width, setWidth] = useState<number | null>(null);

  useLayoutEffect(() => {
    if (target) setWidth(target.current.getBoundingClientRect().width);
  }, [target]);

  useResizeObserver(target, (entry) => setWidth(entry.contentRect.width));

  return width;
};

interface Props {
  url: string;
  previewPage: number | null;
  searchText?: string | null;
  pdfFullHeight: boolean;
  setPdfFullHeight: (pdfFullHeight: boolean) => void;
}

const PdfFileRenderer = (props: Props): JSX.Element => {
  const { url, previewPage, searchText, pdfFullHeight, setPdfFullHeight } = props;
  const [scale, setScale] = useState(1);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const pdfWrapper = useRef<HTMLDivElement>(null);
  const width = useWidth(pdfWrapper);

  const onDocumentLoadSuccess = useCallback(
    ({ numPages }: { numPages: number }) => {
      setNumPages(numPages);
      setPageNumber(previewPage && previewPage > 0 && previewPage <= numPages ? previewPage : 1);
    },
    [previewPage]
  );

  const changePage = useCallback((offset: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }, []);

  const previousPage = useCallback(() => {
    changePage(-1);
  }, [changePage]);

  const nextPage = useCallback(() => {
    changePage(1);
  }, [changePage]);

  const changeScale = useCallback((offset: number) => {
    setScale((prevScale) => prevScale + offset);
  }, []);

  const decreaseScale = useCallback(() => {
    changeScale(-0.1);
  }, [changeScale]);

  const increaseScale = useCallback(() => {
    changeScale(0.1);
  }, [changeScale]);

  const highlightText = useCallback(() => {
    if (!searchText) return;
    const searchTextLowerCase = searchText.toLowerCase();
    const replaceRegExp = new RegExp(searchText, 'gi');
    const selector = '.react-pdf__Page__textContent [role="presentation"]';

    document.querySelectorAll(selector).forEach((element) => {
      if (!element.textContent) return;
      const spanTextContent = element.textContent.trim().toLowerCase();
      if (spanTextContent === '') return;

      // searchText is 'larger' than spanTextContent
      if (searchTextLowerCase.includes(spanTextContent)) {
        element.innerHTML = `<mark>${element.innerHTML}</mark>`;

        return;
      }

      // spanTextContent is 'larger' than searchText
      if (spanTextContent.includes(searchTextLowerCase)) {
        element.innerHTML = element.innerHTML.replace(replaceRegExp, `<mark>${searchText}</mark>`);

        return;
      }
    });
  }, [searchText]);

  return (
    <>
      <Grid item style={{ margin: 'auto' }}>
        <Typography variant="body2">
          <Tooltip title="Previous Page">
            <span>
              <IconButton aria-label="previous page" disabled={pageNumber <= 1} onClick={previousPage}>
                <ArrowBackIosIcon />
              </IconButton>
            </span>
          </Tooltip>
          {pageNumber || (numPages ? 1 : '--')} / {numPages || '--'}
          <Tooltip title="Next Page">
            <span>
              <IconButton aria-label="next page" disabled={!numPages || pageNumber >= numPages} onClick={nextPage}>
                <ArrowForwardIosIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Zoom Out">
            <span>
              <IconButton aria-label="zoom out" disabled={scale <= 0.5} onClick={decreaseScale}>
                <ZoomOutIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Zoom In">
            <span>
              <IconButton aria-label="zoom in" disabled={scale >= 2} onClick={increaseScale}>
                <ZoomInIcon />
              </IconButton>
            </span>
          </Tooltip>
          {pdfFullHeight ? (
            <Tooltip title="Exit Full Screen">
              <IconButton aria-label="exit full screen" onClick={() => setPdfFullHeight(false)}>
                <CloseFullscreenIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Switch to Full Screen">
              <IconButton aria-label="switch to full screen" onClick={() => setPdfFullHeight(true)}>
                <OpenInFullIcon />
              </IconButton>
            </Tooltip>
          )}
        </Typography>
      </Grid>
      <Grid
        item
        style={{
          width: '100%',
          height: '100%',
          overflow: 'auto',
          border: '1px solid black',
          flexGrow: '1',
        }}
        ref={pdfWrapper}
      >
        <Document
          onLoadError={console.error}
          file={url}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={<CircularProgress />}
        >
          {pageNumber && (
            <Page
              scale={scale}
              pageNumber={pageNumber}
              width={width ? width : 0}
              onRenderTextLayerSuccess={() => {
                if (pageNumber === previewPage && searchText && searchText !== '') highlightText();
              }}
            />
          )}
        </Document>
      </Grid>
    </>
  );
};

export default PdfFileRenderer;
