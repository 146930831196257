// HomePage.tsx
import React from 'react';
import { Link } from 'react-router-dom';

export default function NotFound(): JSX.Element {
  return (
    <div>
      <h2>This page doesn't exist</h2>
      <Link to="/">Go back home</Link>
    </div>
  );
};
