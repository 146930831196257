import React, { ChangeEvent, useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, parseISO } from 'date-fns';
import enLocale from 'date-fns/locale/en-US';
import ExpandMoreIcon from '@mui/icons-material//ArrowForwardIosSharp';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Alert from '@mui/material/Alert';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import { getButtonClass, WORKFLOW_ACTION_SLUG_SUFFIXES } from './PatientHCCs';
import CloseIcon from '@mui/icons-material/Close';
import { HccCode } from '../../types/Hcc';
import { snomedCode } from '../../types/Hcc';
import { RootState } from '../../app/store'; // Replace with your app's root state type

import { closeActionModal } from '../../features/modals/action-modal-slice'; // Replace with the correct path to the modalSlice actions
import ModalBox from '../ModalBox';
import { useLazyGetPatientHccsQuery, useSetIcdCodeMutation } from '../../api/api';
import { showToastMsg } from '../../features/toast-message-slice';
import { HccParams } from '../../types/Hcc';

interface ActionModalProps {
  // Props for the modal component, e.g., onClose
}

const treatmentPlanOptions = [
  'Lab',
  'Follow-up Visit',
  'Imaging',
  'Special Study',
  'Referral',
  'Other (please specify)',
];
const progressionOptions = ['Improving', 'Stable', 'Worsening'];

const getDefaultMatchedSnomedCode = (snomedCodes: snomedCode[]) => {
  return snomedCodes.find((snomedCode) => snomedCode.defaultMatch);
};

export interface IcdPayload {
  hccId?: string;
  slug?: string;
  notes?: string;
  snomedCode?: string;
  snomedCodeDescription?: string;
  dateOfService?: string;
  severity?: string;
  treatmentPlan?: string;
  progression?: string;
  encounterId?: string;
}

const muiClasses: { [key: string]: string } = {
  query_provider: '!bg-success-main/40 !mr-2 !border-[0.5x] !border-success-main !text-black !font-bold',
  confirm: '!bg-success-main/40 !mr-2 !border-[0.5x] !border-error-main !text-black !font-bold',
  deny: '!bg-error-main/40 !mr-2 !border-[0.5x] !border-success-main !text-black !font-bold',
  back_to_coder: '!bg-mediumPurple/40 !mr-2 !border-[0.5x] !border-mediumPurple !text-black',
};

const ActionModal: React.FC<ActionModalProps> = () => {
  const { patientId } = useParams<HccParams>();
  const dispatch = useDispatch();
  const isOpen = useSelector((state: RootState) => state.actionModal.isOpen);
  const data = useSelector((state: RootState) => state.actionModal.data);
  const hcc = useSelector((state: RootState) => state.actionModal.data?.hcc);
  const pickOpenEncounterToAcceptICD = useSelector(
    (state: RootState) => state.actionModal.data?.pickOpenEncounterToAcceptICD
  );
  const encounters = useSelector((state: RootState) => state.actionModal.data?.encounters);
  const slug = useSelector((state: RootState) => state.actionModal.data?.slug);

  const [setIcdCodeMutation, { isLoading: loading }] = useSetIcdCodeMutation();
  const [getPatientHccsQuery] = useLazyGetPatientHccsQuery();

  const handleClose = useCallback(() => {
    dispatch(closeActionModal());
  }, [dispatch]);

  const [dos, setDos] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [treatmentPlan, setTreatmentPlan] = useState('');
  const [progression, setProgression] = useState('');
  const [encounterId, setEncounterId] = useState('');
  const [snomedCode, setSnomedCode] = useState('');
  const defaultMatchedSnomedCode = getDefaultMatchedSnomedCode(data?.hcc?.snomedCodes || []);
  const [nonDefaultMatchedSnomedCode, setNonDefaultMatchedSnomedCode] = useState(defaultMatchedSnomedCode?.code || '');
  const [nonDefaultMatchedSnomedCodesOpen, setNonDefaultMatchedSnomedCodesOpen] = useState(false);
  const [internalNotes, setInternalNotes] = useState('');
  const snomedCodeRequired = data?.hcc?.snomedCodes && data.hcc.snomedCodes.length > 0;

  useEffect(() => {
    setSnomedCode(() => {
      if (!data?.hcc?.snomedCodes?.length) return '';

      if (data.hcc.snomedCodes.length === 1) {
        return data.hcc.snomedCodes[0].code;
      }

      const defaultMatchedSnomedCode = getDefaultMatchedSnomedCode(data.hcc.snomedCodes);

      return defaultMatchedSnomedCode ? defaultMatchedSnomedCode.code : '';
    });
    setNonDefaultMatchedSnomedCode(defaultMatchedSnomedCode?.code || '');

    setEncounterId(encounters && encounters.length ? encounters[0].encounterId : '');
  }, [data?.hcc?.snomedCodes, defaultMatchedSnomedCode?.code, encounters]);

  const getSnomedCodeDescription = (snomedCode: string) => {
    const snomedCodes = hcc?.snomedCodes || [];

    for (let i = 0; i < snomedCodes.length; i++) {
      if (snomedCodes[i].code === snomedCode) return snomedCodes[i].description;
    }

    return '';
  };

  const setIcdCode = async () => {
    const payload: IcdPayload = {
      hccId: hcc?._id,
      slug,
      notes: internalNotes,
      encounterId,
    };

    let effectiveSnomedCode = null;

    if (snomedCodeRequired) {
      effectiveSnomedCode = nonDefaultMatchedSnomedCodesOpen ? nonDefaultMatchedSnomedCode : snomedCode;
      payload.snomedCode = effectiveSnomedCode;
      payload.snomedCodeDescription = getSnomedCodeDescription(effectiveSnomedCode);
    }

    if (dos) {
      payload.dateOfService = dos;
    }
    if (treatmentPlan) {
      payload.treatmentPlan = treatmentPlan;
    }
    if (progression) {
      payload.progression = progression;
    }

    try {
      if (!internalNotes || (snomedCodeRequired && !effectiveSnomedCode)) {
        throw new Error('Required fields missing');
      }

      setIcdCodeMutation(payload)
        .unwrap()
        .then((success: any) => {
          if (success) {
            dispatch(
              showToastMsg({
                open: true,
                message: 'Code updated successfully',
                level: 'success',
                duration: 5000,
              })
            );
            handleClose();
            getPatientHccsQuery(patientId || '');
          } else {
            throw new Error(success);
          }
        })
        .catch((error: any) => {
          dispatch(
            showToastMsg({
              open: true,
              message: error?.message ?? 'Something went wrong',
              level: 'error',
              duration: 5000,
            })
          );
        });
    } catch (error: any) {
      dispatch(
        showToastMsg({
          open: true,
          level: 'error',
          duration: 5000,
        })
      );
    }
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setInternalNotes(e.target.value);
  };

  const getSnomedInputCodeField = (hcc: HccCode | undefined) => {
    if (!snomedCodeRequired || !hcc || !hcc?.snomedCodes || !hcc.snomedCodes.length) {
      return null;
    }

    if (hcc.snomedCodes.length === 1) {
      return (
        <div className="mb-1">
          <div className="hidden">
            <TextField
              type="hidden"
              id="snomedCode"
              label="Snomed Code"
              value={snomedCode}
              InputProps={{
                readOnly: true,
              }}
            />
          </div>
          <Typography variant="body1">
            SNOMED: {hcc.snomedCodes[0].code} - {hcc.snomedCodes[0].description}
          </Typography>
        </div>
      );
    }

    if (!defaultMatchedSnomedCode) {
      return (
        <div className="mb-1">
          <FormControl disabled={loading} fullWidth required={true}>
            <InputLabel variant="outlined" id="snomedCode-label">
              Find Matching SNOMED Code
            </InputLabel>
            <Select
              required={true}
              variant="outlined"
              label="Find Matching SNOMED Code"
              id="snomedCode"
              value={snomedCode}
              onChange={(e) => setSnomedCode(e.target.value)}
              fullWidth
              input={<OutlinedInput id="select-multiple-chip" label="Find Matching SNOMED Code" />}
            >
              {hcc.snomedCodes.map((snomedCode) => (
                <MenuItem key={snomedCode.code} value={snomedCode.code}>
                  {snomedCode.code} - {snomedCode.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      );
    }

    return (
      <div className="mb-1">
        <Accordion
          className="[&.Mui-expanded]:rounded [&.MuiPaper-root]:shadow-none [&.MuiButtonBase-root]:border-0"
          onChange={(e, expanded) => setNonDefaultMatchedSnomedCodesOpen(expanded)}
        >
          <AccordionSummary
            className="!p-0 flex-row-reverse [&_.MuiAccordionSummary-expandIconWrapper.Mui-expanded]:rotate-90"
            expandIcon={<ExpandMoreIcon className="!text-base" />}
            aria-controls="alternative-icd-snomed-maps-content"
            id={`alternative-icd-snomed-maps-content${defaultMatchedSnomedCode.code}`}
          >
            <div>
              <div className="hidden">
                <TextField
                  type="hidden"
                  id="snomedCode"
                  value={defaultMatchedSnomedCode.code}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <Typography variant="body1" className="pl-1">
                SNOMED: {defaultMatchedSnomedCode.code} - {defaultMatchedSnomedCode.description}
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl disabled={loading} fullWidth>
              <InputLabel id="snomedCode-label">Select other matching SNOMED</InputLabel>
              <Select
                label="Select other matching SNOMED"
                id="snomedCode"
                value={nonDefaultMatchedSnomedCode}
                onChange={(e) => setNonDefaultMatchedSnomedCode(e.target.value)}
                input={<OutlinedInput id="select-multiple-chip" label="Select other matching SNOMED" />}
              >
                {hcc.snomedCodes.map((snomedCode) => (
                  <MenuItem key={snomedCode.code} value={snomedCode.code}>
                    {snomedCode.code} - {snomedCode.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  };

  const getEncounterOptions = () => {
    if (!pickOpenEncounterToAcceptICD) return null;

    if (!encounters || !encounters.length) {
      return (
        <Alert severity="error" className="mb-1">
          No open encounter found.
        </Alert>
      );
    }

    return (
      <div className="mt-2 mb-2">
        <FormControl disabled={loading} fullWidth required={true}>
          <InputLabel id="encounter-label">Select Matching Encounter</InputLabel>
          <Select
            required={true}
            label="Select Matching Encounter"
            id="encounter"
            value={encounterId}
            onChange={(e) => setEncounterId(e.target.value)}
            input={<OutlinedInput id="select-multiple-chip-encounter" label="Select Matching Encounter" />}
          >
            {encounters.map((encounter) => (
              <MenuItem key={encounter.encounterId} value={encounter.encounterId}>
                {dayjs(encounter.encounterDate).format('MM-DD-YYYY')} - {encounter.type} - {encounter.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };

  return (
    <>
      <Modal open={isOpen} onClose={handleClose}>
        <ModalBox
          closeButton={
            <Button className="!absolute right-2 top-2 !text-red-600" onClick={handleClose}>
              <CloseIcon />
            </Button>
          }
        >
          <div className="!mb-2">
            <Typography id="actions-modal" variant="h2" component="h2" className="text-mediumPurple">
              HCC {hcc?.hccCategory}
              {hcc?.hccCategoryDescription ? ` (${hcc?.hccCategoryDescription})` : ''}
            </Typography>
            <Typography id="actions-modal" variant="h2" component="h2">
              <span className="text-success-dark">{hcc?.icdCode}</span> - {hcc?.icdCodeDescription}
            </Typography>
          </div>
          {slug && slug.endsWith(WORKFLOW_ACTION_SLUG_SUFFIXES.CONFIRM) ? (
            <div>
              <div className="flex gap-3 mt-4 mb-1">
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
                  <DatePicker
                    label="Date of Service (default today)"
                    value={parseISO(dos)}
                    onChange={(value: Date | null) => setDos(value ? format(value, 'yyyy-MM-dd') : '')}
                    slotProps={{
                      textField: {
                        InputProps: {
                          required: true,
                          type: 'outlined',
                          error: false,
                        },
                        variant: 'standard',
                      },
                    }}
                    disabled={loading}
                  />
                </LocalizationProvider>
              </div>
              <div className="flex">
                <FormControl disabled={loading} fullWidth style={{ marginRight: '8px' }}>
                  <InputLabel variant="outlined" id="treatmentPlan-label">
                    Treatment Plan
                  </InputLabel>
                  <Select
                    variant="outlined"
                    label="treatmentPlan"
                    id="treatmentPlan"
                    value={treatmentPlan}
                    onChange={(e) => setTreatmentPlan(e.target.value)}
                    fullWidth
                    input={<OutlinedInput id="select-multiple-chip" label="treatmentPlan-label" />}
                  >
                    {treatmentPlanOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl disabled={loading} fullWidth>
                  <InputLabel variant="outlined" id="progression-label">
                    Progression
                  </InputLabel>
                  <Select
                    variant="outlined"
                    label="progression"
                    id="progression"
                    value={progression}
                    onChange={(e) => setProgression(e.target.value)}
                    fullWidth
                    input={<OutlinedInput id="select-multiple-chip" label="progression-label" />}
                  >
                    {progressionOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div>
                <InputLabel className="mt-2 mb-1 text-black" htmlFor="notes" required>
                  Treament Plan Notes
                </InputLabel>
                <TextField
                  multiline
                  fullWidth
                  required
                  className="!mb-2 [&_.MuiInputBase-input]:overflow-auto"
                  value={internalNotes}
                  onChange={handleChange}
                  minRows={2}
                  maxRows={2}
                />
              </div>
              {getSnomedInputCodeField(data?.hcc)}
              {getEncounterOptions()}
            </div>
          ) : (
            <div>
              <InputLabel className="mt-2 mb-1 text-black" htmlFor="notes" required>
                Additional Notes
              </InputLabel>
              <TextField
                multiline
                fullWidth
                required
                className="!mb-2 [&_.MuiInputBase-input]:overflow-auto"
                value={internalNotes}
                onChange={handleChange}
                minRows={2}
                maxRows={2}
              />
            </div>
          )}
          <div>
            {data?.hcc?.snomedCodes && !data.hcc.snomedCodes.length && (
              <Alert severity="error" className="mb-1">
                Warning: Missing SNOMED Code. This ICD cannot be written back to your EHR. Please contact us at{' '}
                <a href="mailto:someone@example.com">support@mdportals.com</a>
              </Alert>
            )}
            <Button
              key={slug}
              disabled={loading || !internalNotes || (snomedCodeRequired && !snomedCode)}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                setIcdCode();
              }}
              className={`${getButtonClass(
                slug || '',
                muiClasses
              )} !mr-2 py-[12px] px-2 text-base rounded [&.Mui-disabled]:!bg-gray-200 [&.Mui-disabled]:!text-gray-500 !normal-case whitespace-nowrap`}
            >
              {loading ? 'Loading...' : data?.label}
            </Button>
            <Button
              disabled={loading}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                handleClose();
              }}
              className="!text-black !normal-case"
            >
              Cancel
            </Button>
          </div>
        </ModalBox>
      </Modal>
    </>
  );
};

export default ActionModal;
