import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HccCode, Encounter } from '../../types/Hcc';

interface ActionModalState {
  isOpen: boolean;
  data: {
    slug: string;
    label: string;
    hcc: HccCode;
    pickOpenEncounterToAcceptICD: boolean;
    encounters: Encounter[];
  } | null;
}

const initialState: ActionModalState = {
  isOpen: false,
  data: null,
};

const modalSlice = createSlice({
  name: 'actionModal',
  initialState,
  reducers: {
    openActionModal: (
      state,
      action: PayloadAction<{
        slug: string;
        label: string;
        hcc: HccCode;
        pickOpenEncounterToAcceptICD: boolean;
        encounters: Encounter[];
      }>
    ) => {
      state.isOpen = true;
      state.data = action.payload;
    },
    closeActionModal: (state) => {
      state.isOpen = false;
      state.data = null;
    },
  },
});

export const { openActionModal, closeActionModal } = modalSlice.actions;

export default modalSlice.reducer;
