import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface UserParamsState {
  user_email: string;
  access_token: string;
}

const initialState: UserParamsState = {
  user_email: '',
  access_token: ''
};

const userParamsSlice = createSlice({
  name: 'userParams',
  initialState,
  reducers: {
    setUserParams: (state, action: PayloadAction<UserParamsState>) => {
      state.user_email = action.payload.user_email;
      state.access_token = action.payload.access_token;
    },
  },
});

export const { setUserParams } = userParamsSlice.actions;

export default userParamsSlice.reducer;
