// auth/LoginPage.tsx
import React, { useState } from 'react';
// import { useGetPostsQuery } from '../api/api';
import { useDispatch } from 'react-redux';
import { setLoggedIn } from '../features/auth-slice';
import { useNavigate, Link } from 'react-router-dom';

const LoginPage: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  // const [login, { isLoading, isError }] = useLoginMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      console.log('logging in...')
      // await login({ username, password }).unwrap();
      dispatch(setLoggedIn(true));
      navigate('/protected');
    } catch (error) {
      console.error('Failed to login: ', error);
    }
  };

  return (
    <>
      <form onSubmit={handleLogin}>
        <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
        <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
        <button type="submit">Log In</button>
            {/* {isError && <p>Invalid credentials. Please try again.</p>} */}
      </form>
      <Link to="/">Go to Home Page</Link>
    </>
  );
};

    export default LoginPage;