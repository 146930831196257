import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../app/store';
import { showToastMsg } from '../features/toast-message-slice';

const ToastMsg: React.FC = () => {
  const dispatch = useDispatch();
  const toastMsg = useSelector((state: RootState) => state.toastMsg);

  const { open, message, level, duration } = toastMsg;

  const handleClose = () => {
    dispatch(showToastMsg({ open: false }));
  };
  
  return (
    <Snackbar
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Alert onClose={handleClose} severity={level}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default ToastMsg;
