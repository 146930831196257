import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { setLoggedOut } from '../features/auth-slice';
import { setFinishedLoading } from '../features/loading-slice';
// import { log } from '../utils/jsUtils';


/**
 * A react-router-dom route that automatically redirects to login
 * when visited by a user that does not have a valid session
 */
export default function ProtectedRoute(props: {element: () => JSX.Element}): JSX.Element {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  
  const shouldBeLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);

  const logout = useCallback(async() => {
    dispatch(setLoggedOut());
    navigate('/login');
  }, [navigate, dispatch]);

  useEffect(() => {
    const getUser = async () => {
      try {
        if (!shouldBeLoggedIn) {
          navigate(`/login?path=${window.location.pathname}`);
        }
      } finally {
        dispatch(setFinishedLoading());
      }
    }

    getUser();
  }, [dispatch, navigate, logout, shouldBeLoggedIn]);

  return (
    <props.element />
  );
}
