// AboutPage.tsx
import React from 'react';
import { Link } from 'react-router-dom';

export default function AboutPage(): JSX.Element {
  return (
    <div>
      <h2>About Page</h2>
      <Link to="/">Go to Home Page</Link>
    </div>
  );
};