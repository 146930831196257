import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { HccCode, Encounter } from '../../types/Hcc';
import { openActionModal } from '../../features/modals/action-modal-slice';
import { getButtonClass, WORKFLOW_ACTION_SLUG_SUFFIXES } from './PatientHCCs';
import ForwardRef from '../ForwardRef';
import { openInternalNoteModal } from '../../features/modals/internal-note-modal-slice';

type HccSettingsButtonProps = {
  hcc: HccCode;
  pickOpenEncounterToAcceptICD: boolean;
  encounters: Encounter[];
};

const muiClasses: any = {
  query_provider: '!bg-success-main/40 !mr-2 !border-[0.5x] !border-success-main !text-black !font-bold',
  confirm: '!bg-success-main/40 !mr-2 !border-[0.5x] !border-error-main !text-black !font-bold',
  deny: '!bg-error-main/40 !mr-2 !border-[0.5x] !border-success-main !text-black !font-bold',
  back_to_coder: '!bg-mediumPurple/40 !mr-2 !border-[0.5x] !border-mediumPurple !text-black !font-bold',
};

const HccSettingsButton = (props: HccSettingsButtonProps) => {
  const { hcc, pickOpenEncounterToAcceptICD, encounters } = props;

  const hccId = hcc._id;
  const actions = hcc.actions;
  const icdCode = hcc.icdCode;

  const dispatch = useDispatch();

  const [loading] = useState(false);

  type CustomMenuItemProps = {
    label: string;
    slug: string;
    hcc: HccCode;
    pickOpenEncounterToAcceptICD: boolean;
    encounters: Encounter[];
    hccId: string;
    disabled: boolean;
  };

  const CustomMenuItem = (props: CustomMenuItemProps) => {
    const { slug, disabled, label, hcc, pickOpenEncounterToAcceptICD, encounters } = props;

    return (
      <Button
        key={slug}
        disabled={disabled}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          dispatch(
            openActionModal({
              slug,
              label,
              hcc,
              pickOpenEncounterToAcceptICD,
              encounters,
            })
          );
        }}
        className={`${getButtonClass(
          slug,
          muiClasses
        )} mr-2 py-[12px] px-2 !text-[0.9rem] rounded [&.Mui-disabled]: bg-inherit/10 text-gray-500 !normal-case whitespace-nowrap`}
      >
        {label}
      </Button>
    );
  };

  return (
    <div className="relative flex items-center z-999">
      {actions.map((a) =>
        a.slug.endsWith(WORKFLOW_ACTION_SLUG_SUFFIXES.INTERNAL_NOTE) ? (
          <Tooltip title="Add internal Note" key={a.slug}>
            <ForwardRef>
              <FontAwesomeIcon
                style={{ color: 'black', height: '20px' }}
                icon={regular('comment-alt')}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  dispatch(
                    openInternalNoteModal({
                      hccId,
                      icdCode,
                      hcc,
                    })
                  );
                }}
              />
            </ForwardRef>
          </Tooltip>
        ) : (
          <CustomMenuItem
            hcc={hcc}
            hccId={hccId}
            pickOpenEncounterToAcceptICD={pickOpenEncounterToAcceptICD}
            encounters={encounters}
            key={a.slug}
            slug={a.slug}
            disabled={loading}
            label={a.label}
          />
        )
      )}

      {/* <Tooltip title="View Changelog">
        <ForwardRef>
          <FontAwesomeIcon
            style={{ color: 'black', height: '20px' }}
            icon={solid('circle-info')}
          />
        </ForwardRef>
      </Tooltip> */}
    </div>
  );
};

export default HccSettingsButton;
