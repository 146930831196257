import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import store from './app/store';
import Routes from './Routes';
import ToastMsg from './components/ToastMsg';
import theme from './theme';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <div className="bg-alabaster">
          <Routes />
          <ToastMsg />
        </div>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
