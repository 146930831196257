// store.ts
import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth-slice';
import loadingReducer from '../features/loading-slice';
import actionModalReducer from '../features/modals/action-modal-slice';
import internalModalReducer from '../features/modals/internal-note-modal-slice';
import hipaaLogModalReducer from '../features/modals/hipaa-log-modal-slice';
import pdfModalReducer from '../features/modals/pdf-modal-slice';
import toastMsgReducer from '../features/toast-message-slice';
import userParamsReducer from '../features/user-params-slice';
import { setupListeners } from '@reduxjs/toolkit/query';
import { apiSlice } from '../api/api';

const store = configureStore({
  reducer: {
    auth: authReducer,
    loading: loadingReducer,
    actionModal: actionModalReducer,
    internalNoteModal: internalModalReducer,
    hipaaLogModal: hipaaLogModalReducer,
    pdfModal: pdfModalReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    toastMsg: toastMsgReducer,
    userParams: userParamsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
