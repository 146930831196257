// MemberPage.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '../app/hooks';
import { setLoggedOut } from '../features/auth-slice';
import { Typography } from '@mui/material';


export default function MemberPage(): JSX.Element {
  const dispatch = useAppDispatch();
  
  const logout = () => {
    dispatch(setLoggedOut());
  }

  return (
    <div>
      <h2>Protected Member Page</h2>
      <Link to="/">Go to Home Page</Link>
      <Link to='#' onClick={logout}><Typography variant='body1' >Logout</Typography></Link>
    </div>
  );
};