import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HccCode } from '../../types/Hcc';

interface HipaaLogModalState {
  isOpen: boolean;
  hcc: HccCode | null;
}

const initialState: HipaaLogModalState = {
  isOpen: false,
  hcc: null,
};

const modalSlice = createSlice({
  name: 'hipaaLogModal',
  initialState,
  reducers: {
    openHipaaLogModal: (state, action: PayloadAction<HccCode>) => {
      state.isOpen = true;
      state.hcc = action.payload;
    },
    closeHipaaLogModal: (state) => {
      state.isOpen = false;
      state.hcc = null;
    },
  },
});

export const { openHipaaLogModal, closeHipaaLogModal } = modalSlice.actions;

export default modalSlice.reducer;
