// AboutPage.tsx
import React, { useEffect, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import PatientHCCs from './hcc/PatientHCCs';
import Container from '@mui/material/Container';

import { HccParams } from '../types/Hcc';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../app/store';
import { setUserParams } from '../features/user-params-slice';

export default function CompendiumPage(): JSX.Element {
  const { patientId = '' } = useParams<HccParams>();

  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const user_email_params = useMemo(() => searchParams.get('user_email'), [searchParams]);
  const access_token_params = useMemo(() => searchParams.get('access_token'), [searchParams]);

  const { user_email, access_token } = useSelector((state: RootState) => state.userParams);

  useEffect(() => {
    if (user_email_params && access_token_params) {
      dispatch(
        setUserParams({
          user_email: user_email_params,
          access_token: access_token_params,
        })
      );
    }
  }, [user_email_params, access_token_params, dispatch]);

  return (
    <Container maxWidth={false} className="mt-0">
      {user_email && access_token && <PatientHCCs patientId={patientId} />}
    </Container>
  );
}
