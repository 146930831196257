import { RouterProvider, createBrowserRouter } from "react-router-dom";
import HomePage from "./components/HomePage";
import MemberPage from "./components/MemberPage";
import NotFound from "./components/NotFound";
import ProtectedRoute from "./components/ProtectedRoute";
import AboutPage from "./components/AboutPage";
import LoginPage from "./components/LoginPage";
import CompendiumPage from "./components/CompendiumPage";

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />
  }, {
    path: '/about',
    element: <AboutPage />
  }, {
    path: '/protected',
    element: <ProtectedRoute element={MemberPage} />
  }, {
    path: '/patient/:patientId',
    element: <CompendiumPage /> // TODO: @danny: make this protected
  }, {
    path: '/login',
    element: <LoginPage />
  }, {
    path: '*',
    element: <NotFound />
  }
]);

export default function Links() {
  // TODO: add here logic to redirect on Auth logout

  return (
    <RouterProvider router={router} />
  );
};