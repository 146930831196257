import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import { useLazyGetHccSourceDocumentQuery } from '../../api/api';
import { RootState } from '../../app/store'; // Replace with your app's root state type
import { closePdfModal } from '../../features/modals/pdf-modal-slice'; // Replace with the correct path to the modalSlice actions
import { HccParams } from '../../types/Hcc';
import ModalBox from '../ModalBox';
import PdfFileRenderer from './PdfFileRenderer';

const renderFileName = (filename: string) => {
  const MAX_CHARS = 100;

  if (filename.length <= MAX_CHARS) return filename;
  const extension = filename.slice(filename.lastIndexOf('.'));
  const truncatedName = filename.slice(0, MAX_CHARS - extension.length - 3);

  return truncatedName + '...' + extension;
};
interface PdfModalProps {
  // Props for the modal component, e.g., onClose
}

const PdfModal: React.FC<PdfModalProps> = () => {
  const { patientId = '' } = useParams<HccParams>();
  const dispatch = useDispatch();
  const isOpen = useSelector((state: RootState) => state.pdfModal.isOpen);
  const hccRecentSource = useSelector((state: RootState) => state.pdfModal.hccRecentSource);
  const [getHccSourceDocument, { data: fileData, isFetching: fetching }] = useLazyGetHccSourceDocumentQuery();
  const [pdfFullHeight, setPdfFullHeight] = useState(false);

  const handleClose = useCallback(() => {
    dispatch(closePdfModal());
  }, [dispatch]);

  const getDocument = useCallback(async () => {
    try {
      if (hccRecentSource) {
        getHccSourceDocument({ patientId, fileHash: hccRecentSource?.sourceLocation?.hash || '' });
      }
    } catch (error) {}
  }, [getHccSourceDocument, patientId, hccRecentSource]);

  useEffect(() => {
    getDocument();
  }, [getDocument]);

  const Loading = () => {
    return (
      <div className="w-full flex justify-center">
        <CircularProgress />
      </div>
    );
  };
  console.log(fileData);
  const PdfData = () => {
    if (!fileData) return null;

    return (
      <Grid container direction="column" style={{ height: '100%', flexWrap: 'nowrap' }}>
        {!pdfFullHeight && (
          <Grid item>
            <Typography variant="h1" className="file-info-title !mb-2.5 !font-semibold">
              {renderFileName(fileData.name)}
            </Typography>
          </Grid>
        )}
        {fileData.metaDataPreview && !pdfFullHeight && (
          <Grid item>
            <div className="relative flex flex-wrap [&_p]:text-base">
              {Object.keys(fileData.metaDataPreview).map((key, idx) => (
                <div className="file-info-row mb-[10px] flex items-center basis-1/2" key={idx}>
                  <Typography variant="body1" className="file-info-key w-[200px]">
                    {key}:
                  </Typography>
                  <Typography variant="body1" className="file-info-value font-semibold">
                    {fileData.metaDataPreview[key]}
                  </Typography>
                </div>
              ))}
            </div>
          </Grid>
        )}
        <PdfFileRenderer
          url={fileData?.previewUrl}
          previewPage={hccRecentSource?.sourceLocation?.page ? parseInt(hccRecentSource?.sourceLocation?.page) : null}
          searchText={hccRecentSource?.excerpt}
          pdfFullHeight={pdfFullHeight}
          setPdfFullHeight={setPdfFullHeight}
        />
      </Grid>
    );
  };

  return (
    <>
      <Modal open={isOpen} onClose={handleClose} aria-labelledby="actions-modal">
        <ModalBox
          className="w-full h-full"
          closeButton={
            <Button className="!absolute right-2 top-2 !text-red-600" onClick={handleClose}>
              <CloseIcon />
            </Button>
          }
        >
          {fetching ? <Loading /> : <PdfData />}
        </ModalBox>
      </Modal>
    </>
  );
};

export default PdfModal;
