// HomePage.tsx
import React from 'react';

export default function HomePage(): JSX.Element {
  return (
    <div>
      MDPortals, a <a href='https://reveleer.com'>Reveleer</a> company.
    </div>
  );
};
