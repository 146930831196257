import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { HccCode } from '../../types/Hcc';
import IcdSourceCard from './IcdSourceCard';

type Props = {
  patientHccs: any;
  hccLabel: string;
  _key: string;
  [key: string]: any;
};

type ExpandedCard = {
  [key: string]: any;
};

const HccSourceCard = (props: Props) => {
  const { hccLabel, _key, patientHccs, pickOpenEncounterToAcceptICD, encounters } = props;
  const [expandedHccCards, setExpandedHccCards] = useState<ExpandedCard>({});
  const [expandedIcdCards, setExpandedIcdCards] = useState<ExpandedCard>({});

  const handleChangeHccCards = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpandedHccCards({
      ...expandedHccCards,
      [panel]: typeof expandedHccCards[panel] === 'boolean' ? !expandedHccCards[panel] : false,
    });
  };

  const handleChangeIcdCards = (panel: string) => () => {
    const _expandedIcdCards = { ...expandedIcdCards };
    _expandedIcdCards[panel] = !_expandedIcdCards[panel];
    setExpandedIcdCards(_expandedIcdCards);
  };

  return (
    <Accordion
      className="[&.Mui-expanded]:rounded [&.MuiPaper-root]:shadow-none [&.MuiButtonBase-root]:border-0"
      disabled={!patientHccs?.length}
      style={{ zIndex: '1' }}
      expanded={expandedHccCards[_key]}
      defaultExpanded={true}
      onChange={handleChangeHccCards(_key)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="!text-base" />}
        aria-controls={`content-${hccLabel}`}
        id={`header-${hccLabel}`}
        className="h-[70px] py-1 px-2 flex-row-reverse [&_.MuiAccordionSummary-expandIconWrapper.Mui-expanded]:rotate-90"
      >
        <div className="!font-extrabold text-mediumPurple w-full ml-[10px]">{hccLabel} </div>
      </AccordionSummary>
      <AccordionDetails
        className="block px-2 py-4 bg-white border-0"
        style={{
          padding: '1rem',
          borderTop: '1px solid rgba(0, 0, 0, .125)',
        }}
      >
        {patientHccs.map((hcc: HccCode) => (
          <IcdSourceCard
            key={hcc.icdCode}
            _key={`${_key}-${hcc.icdCode}`}
            hcc={hcc}
            pickOpenEncounterToAcceptICD={pickOpenEncounterToAcceptICD}
            encounters={encounters}
            handleChangeIcdCards={handleChangeIcdCards}
            isExpanded={expandedIcdCards[`${_key}-${hcc.icdCode}`]}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default HccSourceCard;
