import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import { capitalizeFirstLetter } from '../../utils/jsUtils';
import { useLazyGetChangelogCompendiumsByHccIdQuery } from '../../api/api';

import { RootState } from '../../app/store'; // Replace with your app's root state type

import { closeHipaaLogModal } from '../../features/modals/hipaa-log-modal-slice'; // Replace with the correct path to the modalSlice actions

import { HccCode } from '../../types/Hcc';
import ModalBox from '../ModalBox';

const USER_TYPE_USER = 'user';
const USER_TYPE_BOT = 'bot';
const USER_TYPE_API = 'API';

const getFullName = (row: any) => {
  return row.user?.firstName + ' ' + row.user?.lastName;
};

const getTitle = (hcc: HccCode | null) => {
  return hcc ? hcc.icdCode + ' - ' + hcc.icdCodeDescription : '';
};

interface HipaaLogModalProps {
  // Props for the modal component, e.g., onClose
}

const HipaaLogModal: React.FC<HipaaLogModalProps> = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector((state: RootState) => state.hipaaLogModal.isOpen);
  const hcc = useSelector((state: RootState) => state.hipaaLogModal.hcc);

  const [getChangelogCompendiumsByHccId, { data: hccChangelogCompendiums, isLoading: loading }] =
    useLazyGetChangelogCompendiumsByHccIdQuery();

  const handleClose = useCallback(() => {
    dispatch(closeHipaaLogModal());
  }, [dispatch]);

  const getChangelog = useCallback(async () => {
    try {
      if (hcc) {
        getChangelogCompendiumsByHccId(hcc._id);
      }
    } catch (error) {}
  }, [hcc, getChangelogCompendiumsByHccId]);

  const hipaaLogColumns = [
    {
      headerName: 'Date',
      field: 'createdDt',
      flex: 1,
      maxWidth: 210,
      valueGetter: (params: any) => {
        return params.value ? dayjs(params.value).format('MM-DD-YYYY hh:mmA') : '';
      },
    },
    {
      headerName: 'User',
      field: 'user',
      flex: 1,
      maxWidth: 270,
      valueGetter: (params: any) => {
        switch (params.row.userType) {
          case USER_TYPE_USER:
            return getFullName(params.row);
          case USER_TYPE_BOT:
            return USER_TYPE_BOT;
          case USER_TYPE_API:
            return USER_TYPE_API;
          default:
            return 'N/A';
        }
      },
    },
    {
      headerName: 'Description',
      field: 'description',
      flex: 1,
      valueGetter: (params: any) => {
        const fullName = getFullName(params.row);
        let description = params.row.description;

        if (description.startsWith(fullName)) {
          description = description.replace(fullName, '');

          if (description[0] === ' ') {
            description = description.replace(' ', '');
          }

          description = capitalizeFirstLetter(description);
        }

        return description;
      },
    },
  ];

  useEffect(() => {
    if (isOpen) {
      getChangelog();
    }
  }, [isOpen, getChangelog]);

  return (
    <>
      <Modal open={isOpen} onClose={handleClose} aria-labelledby="actions-modal">
        <ModalBox
          className="w-full min-h-[300px]"
          closeButton={
            <Button className="!absolute right-2 top-2 !text-red-600" onClick={handleClose}>
              <CloseIcon />
            </Button>
          }
        >
          {loading ? (
            <div className="flex items-center justify-center w-full h-full">
              <CircularProgress />
            </div>
          ) : (
            <div className="max-h-screen overflow-auto">
              <h3>{getTitle(hcc)}</h3>
              {!hccChangelogCompendiums || !hccChangelogCompendiums.length ? (
                <div>No HIPAA Logs available</div>
              ) : (
                <DataGrid
                  columns={hipaaLogColumns}
                  rows={hccChangelogCompendiums}
                  autoHeight
                  rowThreshold={0}
                  getRowId={(row) => row._id}
                  slots={{ toolbar: GridToolbar }}
                  pageSizeOptions={[10, 20, 30, 50, 100, 200]}
                  pagination
                />
              )}
            </div>
          )}
        </ModalBox>
      </Modal>
    </>
  );
};

export default HipaaLogModal;
