import React, { ChangeEvent, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import { useAddInternalNoteMutation, useLazyGetPatientHccsQuery } from '../../api/api';

import CloseIcon from '@mui/icons-material/Close';

import { RootState } from '../../app/store'; // Replace with your app's root state type

import { closeInternalNoteModal } from '../../features/modals/internal-note-modal-slice'; // Replace with the correct path to the modalSlice actions
import ModalBox from '../ModalBox';
import { showToastMsg } from '../../features/toast-message-slice';
import { HccParams } from '../../types/Hcc';

interface InternalNoteModalProps {
  // Props for the modal component, e.g., onClose
}

const InternalNoteModal: React.FC<InternalNoteModalProps> = () => {
  const { patientId } = useParams<HccParams>();
  const dispatch = useDispatch();

  const isOpen = useSelector((state: RootState) => state.internalNoteModal.isOpen);
  const data = useSelector((state: RootState) => state.internalNoteModal.data);
  const hcc = useSelector((state: RootState) => state.internalNoteModal.data?.hcc);

  const handleClose = useCallback(() => {
    dispatch(closeInternalNoteModal());
  }, [dispatch]);

  const [internalNotes, setInternalNotes] = useState('');
  const [addDataMutation, { isLoading: loading }] = useAddInternalNoteMutation();
  const [getPatientHccsQuery] = useLazyGetPatientHccsQuery();

  const addInternalNote = async (hccId: string = '', internalNotes: string) => {
    try {
      if (!internalNotes) {
        console.log('throwww');
        throw new Error('Required field missing');
      }
      await addDataMutation({
        hccId: hccId,
        notes: internalNotes,
      })
        .unwrap()
        .then((success: any) => {
          if (success) {
            dispatch(
              showToastMsg({
                open: true,
                message: 'Internal note added successfully',
                level: 'success',
                duration: 50000,
              })
            );
            setInternalNotes('');
            handleClose();
            getPatientHccsQuery(patientId || '');
          } else {
            throw new Error(success);
          }
        })
        .catch((error: any) => {
          dispatch(
            showToastMsg({
              open: true,
              message: error?.response?.body ?? error.message ?? 'Something went wrong',
              level: 'error',
              duration: 5000,
            })
          );
        });
    } catch (error: any) {
      dispatch(
        showToastMsg({
          open: true,
          message: error?.response?.body ?? error.message ?? 'Something went wrong',
          level: 'error',
          duration: 5000,
        })
      );
    }
    console.log('[addInternalNote] hccId: ', hccId);
    console.log('[addInternalNote] internalNotes: ', internalNotes);
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setInternalNotes(e.target.value);
  };

  return (
    <>
      <Modal open={isOpen} onClose={handleClose} aria-labelledby="actions-modal" className="w-full h-full">
        <ModalBox
          closeButton={
            <Button className="!absolute right-2 top-2 !text-red-600" onClick={handleClose}>
              <CloseIcon />
            </Button>
          }
        >
          <div className="!mb-2">
            <Typography id="actions-modal" variant="h2" component="h2" className="text-mediumPurple">
              HCC {hcc?.hccCategory}
              {hcc?.hccCategoryDescription ? ` (${hcc?.hccCategoryDescription})` : ''}
            </Typography>
            <Typography id="actions-modal" variant="h2" component="h2">
              <span className="text-success-dark">{hcc?.icdCode}</span> - {hcc?.icdCodeDescription}
            </Typography>
          </div>

          <div>
            <InputLabel className="mt-2 mb-1 text-black" htmlFor="notes" required>
              Internal Note
            </InputLabel>
            <TextField
              multiline
              fullWidth
              className="!mb-2 [&_.MuiInputBase-input]:overflow-auto"
              value={internalNotes}
              onChange={handleChange}
              minRows={2}
              maxRows={2}
            />
          </div>
          <div>
            <Button
              disabled={loading}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                addInternalNote(data?.hccId, internalNotes);
              }}
              className={`!mr-2 py-[12px] px-2 text-base rounded !text-black [&.Mui-disabled]:!bg-gray-200 [&.Mui-disabled]:!text-gray-500 !bg-wattle/40 border-[0.5px] border-wattle !font-bold !normal-case`}
            >
              {loading ? 'Loading...' : 'Add internal note'}
            </Button>
          </div>
        </ModalBox>
      </Modal>
    </>
  );
};

export default InternalNoteModal;
