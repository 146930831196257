import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface ToastMsgState {
  open: boolean;
  message?: string;
  level?: 'success' | 'error' | 'warning' | 'info';
  duration?: number;
}

const initialState: ToastMsgState = {
  open: false,
  message: '',
  level: 'success',
  duration: 3000,
};

const toastMsgSlice = createSlice({
  name: 'toastMsg',
  initialState,
  reducers: {
    showToastMsg: (
      state,
      action: PayloadAction<ToastMsgState>
    ) => {
      state.open = action.payload.open;
      state.message = action.payload.message;
      state.level = action.payload.level;
      state.duration = action.payload.duration;
    },
  },
});

export const { showToastMsg } = toastMsgSlice.actions;

export default toastMsgSlice.reducer;
