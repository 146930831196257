import { useEffect, useState, MouseEvent, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Accordion from '@mui/material/Accordion';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ArrowForwardIosSharp';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { HccCode, HccSources, INotes, Encounter, HccTag } from '../../types/Hcc';
import HccSettingsButton from './HccSettingsButton';
import ForwardRef from '../ForwardRef';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useLazyGetIcdSourcesAndNotesQuery, useDeleteIcdNoteMutation } from '../../api/api';
import { showToastMsg } from '../../features/toast-message-slice';
import { useParams, useSearchParams } from 'react-router-dom';
import { openHipaaLogModal } from '../../features/modals/hipaa-log-modal-slice';
import { openPdfModal } from '../../features/modals/pdf-modal-slice';
import PdfButton from './PdfButton';

const muiClasses = {
  accordion: 'bg-jungleMist/20 shadow-accordion rounded',
};

type IcdSourceCardProps = {
  hcc: HccCode;
  pickOpenEncounterToAcceptICD: boolean;
  encounters: Encounter[];
  key: string;
  _key: string;
  isExpanded: boolean;
  handleChangeIcdCards: (panel: string) => () => void;
};

const IcdSourceCard = (props: IcdSourceCardProps): JSX.Element => {
  const { hcc, pickOpenEncounterToAcceptICD, encounters, key: idx, _key, isExpanded, handleChangeIcdCards } = props;
  const [searchParams] = useSearchParams();
  const [paramLoaded, setParamLoaded] = useState(false);
  const cardEl = useRef<HTMLDivElement>(null);
  const sourceEl = useRef<HTMLDivElement>(null);
  const encodeFactName = useCallback((str: string) => encodeURIComponent(str), []);
  const scrollToRef = useCallback(() => {
    const rect = cardEl.current?.getBoundingClientRect();
    if (rect) {
      window.scrollTo({
        top: rect.top + window.scrollY,
        behavior: 'smooth',
      });
    }
  }, [cardEl]);
  const { patientId } = useParams();

  const dispatch = useDispatch();

  const [sources, setSources] = useState<HccSources>({});
  const [notes, setNotes] = useState<INotes[]>([]);

  const [getSourcesAndNotesData, { data: sourcesAndNotesData, isLoading }] = useLazyGetIcdSourcesAndNotesQuery();

  useEffect(() => {
    if (sourcesAndNotesData && sourcesAndNotesData.sources) {
      setSources(sourcesAndNotesData.sources);
    }
    if (sourcesAndNotesData && sourcesAndNotesData.notes) {
      setNotes(sourcesAndNotesData.notes);
    }
  }, [sourcesAndNotesData]);

  useEffect(() => {
    const getIcdSourcesAndNotes = async () => {
      getSourcesAndNotesData({ patientId, icdCodeId: hcc._id });
    };

    if (isExpanded && !Object.keys(sources).length && !notes?.length) {
      getIcdSourcesAndNotes();
    }
  }, [isExpanded, sources, notes, getSourcesAndNotesData, hcc, patientId]);

  useEffect(() => {
    const hccId = searchParams.get('hcc_id');
    if (!paramLoaded && hccId === hcc._id && cardEl.current) {
      scrollToRef();
      cardEl.current?.click();
    }
    setParamLoaded(true);
  }, [hcc, cardEl, scrollToRef, searchParams, paramLoaded]);

  // type SourceHistoryProps = {
  //   sourceHistoryCount: Number;
  //   factName: String;
  //   hcc: String;
  // };

  // const SourceHistory = (props: SourceHistoryProps) => {
  //   const { sourceHistoryCount, factName, hcc } = props;
  //   const [show, setShow] = useState(false);

  //   return (
  //     <div>
  //       <div className="inline-flex items-center cursor-pointer mr-[5px]" onClick={() => setShow(!show)}>
  //         <ExpandMoreIcon
  //           className={`text-[10px] ${show ? 'rotate-90' : 'rotate-0' }`}
  //           style={{
  //             transform: show ? 'rotate(90deg)' : 'rotate(0)',
  //             marginRight: 5,
  //           }}
  //         />{' '}
  //         {/* {show ? 'Hide' : 'Reveal'} {sourceHistoryCount} other {sourceHistoryCount === 1 ? 'source' : 'sources'} for{' '} */}
  //         {factName}
  //       </div>
  //       <div>
  //         {/* {show ? <SourceHistoryList factName={factName} hccId={hcc._id} /> : null} */}
  //       </div>
  //     </div>
  //   );
  // };

  type InternalNotesProps = {
    notes: INotes[];
    hccId: string;
    getSourcesAndNotesData: any;
    isSourceLoading: boolean;
  };

  const InternalNotes = (props: InternalNotesProps) => {
    const { notes, isSourceLoading } = props;
    const [promptNoteDelete, setPromptNoteDelete] = useState(false);
    const [noteToDelete, setNoteToDelete] = useState('');
    const [deleteIcdNoteData, { isLoading }] = useDeleteIcdNoteMutation();

    const handleDeleteNote = (noteId: string) => {
      setNoteToDelete(noteId);
      setPromptNoteDelete(true);
    };

    const handleClosePromptDeleteNote = () => {
      setNoteToDelete('');
      setPromptNoteDelete(false);
    };

    const deleteIcdNote = async () => {
      setPromptNoteDelete(false);
      const originalNotes = [...notes];

      try {
        setNotes(notes.filter((note) => note.id !== noteToDelete));
        const response = await deleteIcdNoteData({ noteId: noteToDelete });
        console.log('[deleteIcdNote] parsedResponse: ', response);

        if (response) {
          dispatch(
            showToastMsg({
              open: true,
              message: 'Note deleted',
              level: 'success',
              duration: 5000,
            })
          );
        }
      } catch (error) {
        setNotes(originalNotes);
        console.log('[deleteIcdNote] error: ', error);
      } finally {
        setNoteToDelete('');
      }
    };

    return (
      <div className="mb-4">
        {isLoading || isSourceLoading ? (
          <CircularProgress style={{ marginTop: 20 }} size={25} />
        ) : (
          notes.map((note) => (
            <div className="p-2 bg-wattle/40 border-[0.5px] border-wattle rounded-lg mb-2" key={note.note}>
              <div className="uppercase font-extrabold text-[0.9rem] text-mineShaft/40">{note.title}</div>
              <div className="mt-2 mb-2 whitespace-pre-wrap">{note.note}</div>
              {note.confirmMetaData ? <div className="mb-2 italic text-[0.95rem]">{note.confirmMetaData}</div> : null}
              <div className="flex item-center text-[0.9rem]">
                <div className="mr-1">
                  <ForwardRef>
                    <FontAwesomeIcon style={{ color: 'black', height: '15px' }} icon={regular('comment-alt')} />
                  </ForwardRef>
                </div>

                <div className="mr-1">{note.userName}</div>
                <div className="mr-1">•</div>
                <div>{note.date}</div>
                {note.actions && note.actions.canDelete && (
                  <>
                    <div className="mx-1">•</div>
                    <Button className="!p-0 !min-w-[24px]" onClick={() => handleDeleteNote(note.id)}>
                      <DeleteIcon color="error" />
                    </Button>
                  </>
                )}
              </div>
            </div>
          ))
        )}
        <Dialog
          open={promptNoteDelete}
          onClose={handleClosePromptDeleteNote}
          aria-labelledby="archive-organization"
          aria-describedby="prompt-archive-organization"
        >
          <DialogContent>
            <DialogContentText id="prompt-archive-organization">
              <div>Are you sure you want to delete this note?</div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosePromptDeleteNote}>No</Button>
            <Button onClick={deleteIcdNote} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  type MoreOptionsProps = {
    hcc: HccCode;
  };

  const MoreOptions = (props: MoreOptionsProps) => {
    const { hcc } = props;
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);

    const handleClick = (e: MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setAnchorEl(e.currentTarget);
    };

    const handleClose = (e: MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setAnchorEl(null);
    };

    return (
      <>
        <IconButton
          aria-label="hcc-more-options-button"
          id={`hcc-more-options-button-${hcc._id}`}
          aria-expanded={open ? 'true' : false}
          aria-haspopup="true"
          onClick={handleClick}
          className="ml-1"
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id={`hcc-more-options-${hcc._id}`}
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: '20ch',
            },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem
            key="show-hipaa-log"
            onClick={(e) => {
              handleClose(e);
              dispatch(openHipaaLogModal(hcc));
            }}
          >
            HIPAA Log
          </MenuItem>
        </Menu>
      </>
    );
  };

  const createTags = (tags: HccTag[]) => {
    if (!tags || !tags.length) return;
    const chips: React.ReactNode[] = [];

    tags.forEach((tag) => {
      const chip = (
        <Chip label={tag.text} variant="outlined" size="medium" style={{ color: tag.color, borderColor: tag.color }} />
      );

      if (!tag.tooltip) {
        chips.push(chip);

        return;
      }

      chips.push(
        <Tooltip arrow placement="top" title={tag.tooltip}>
          {chip}
        </Tooltip>
      );
    });

    return (
      <Stack direction="row" spacing={1}>
        {chips}
      </Stack>
    );
  };

  return (
    <Accordion
      key={idx}
      className={`[&.Mui-expanded]:${muiClasses.accordion} [&.Mui-collapsed]:${muiClasses.accordion} !bg-jungleMist/20`}
      style={{ zIndex: '1' }}
      expanded={isExpanded}
      onChange={handleChangeIcdCards(_key)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="!text-base" />}
        aria-controls={`content-${idx}`}
        ref={cardEl}
        id={`header-${idx}`}
        className="!min-h-[60px] !py-0 !px-2 flex-row-reverse [&_.MuiAccordionSummary-expandIconWrapper.Mui-expanded]:rotate-90 [&>.MuiAccordionSummary-content.Mui-expanded]:!my-0"
      >
        <div className="flex items-center w-full">
          <div className="font-extrabold text-secondary-main w-[70px] ml-[10px]">{hcc.icdCode}</div>
          <span className="font-semibold break-normal mx-[20px]">{hcc.icdCodeDescription}</span>
          {createTags(hcc.tags)}
        </div>
        {hcc?.actions?.length ? (
          <HccSettingsButton
            hcc={hcc}
            pickOpenEncounterToAcceptICD={pickOpenEncounterToAcceptICD}
            encounters={encounters}
          />
        ) : null}
        <MoreOptions hcc={hcc} />
      </AccordionSummary>
      <AccordionDetails
        className="py-4 px-2 !border-0 block bg-white"
        style={{
          padding: '1.5rem',
          borderTop: '1px solid rgba(0, 0, 0, .125)',
        }}
      >
        {notes?.length ? (
          <InternalNotes
            notes={notes}
            hccId={hcc._id}
            getSourcesAndNotesData={getSourcesAndNotesData}
            isSourceLoading={isLoading}
          />
        ) : null}
        <div className="flex items-center justify-between w-full mt-2 mb-4">
          <div>
            {hcc.reason ? (
              <>
                <Typography className="!font-bold nb-1">Reason:</Typography>
                <Typography className="!font-extrabold text-secondary-main">{hcc.reason}</Typography>
              </>
            ) : null}
            {hcc.confidenceScore ? (
              <div className="flex items-center mt-2 mb-4 justify-left">
                <div className="mr-1">
                  <Typography className="font-bold">Confidence Score:</Typography>
                </div>
                <Typography className="font-extrabold text-secondary-main">
                  {parseFloat(hcc.confidenceScore.toString()).toFixed(3)}
                </Typography>
              </div>
            ) : null}
          </div>
          <div className="w-[300px] text-right">
            <span style={{ fontSize: '1rem' }}>{hcc.lastUpdatedFormatted}</span>
            <br />
            <span className="text-[0.8rem]">Last updated</span>
          </div>
        </div>
        <div>
          <Typography className="!font-bold !text-[1.2rem]">Sources</Typography>
          <div ref={sourceEl}>
            {isLoading ? (
              <CircularProgress style={{ marginTop: 20 }} size={25} />
            ) : Object.keys(sources).length ? (
              Object.keys(sources).map((factName, j) => {
                const recentSource = sources[factName].recentSource;
                // const sourceHistoryCount = sources[factName].sourceHistoryCount;

                return (
                  <div key={factName} id={`${encodeFactName(factName)}`} className="!mt-4">
                    <Typography className="!font-bold mb-1">{factName}</Typography>

                    {recentSource ? (
                      <div className="p-1 mt-2 mb-2 border rounded border-mineShaft/20">
                        {recentSource.date ? (
                          <div className="flex items-center mb-1">
                            <Typography className="!text-2xl">{recentSource.date}</Typography>
                          </div>
                        ) : null}
                        {recentSource.factValue ? (
                          <div className="!mt-2 !mb-2">
                            <Typography>Supportive Evidence: {recentSource.factValue}</Typography>
                          </div>
                        ) : null}
                        {recentSource.excerpt ? (
                          <div className="flex items-center mt-1 mb-1 italic">
                            <FontAwesomeIcon style={{ marginRight: 5, color: 'grey' }} icon={solid('quote-left')} />
                            <Typography>{recentSource.excerpt}</Typography>
                          </div>
                        ) : null}
                        {recentSource.annotations ? (
                          <div className="flex items-start w-full !mt-3">
                            {Object.keys(recentSource.annotations).map((annotationName) => (
                              <>
                                {recentSource.annotations[annotationName] ? (
                                  <TextField
                                    size="small"
                                    sx={{
                                      '& .MuiInputBase-input.Mui-disabled': {
                                        WebkitTextFillColor: '#000000',
                                        fontSize: '0.9rem!important',
                                        textAlign: 'left',
                                      },
                                      '& .MuiInputBase-root': {
                                        padding: '8.5px 14px',
                                        background: 'rgba(51, 122, 94, 0.05)',
                                      },
                                    }}
                                    style={{
                                      width: recentSource.annotations[annotationName].length * 6 + 70,
                                    }}
                                    className="!mr-[10px]"
                                    label={annotationName}
                                    value={recentSource.annotations[annotationName]}
                                    disabled
                                  />
                                ) : null}
                              </>
                            ))}
                          </div>
                        ) : null}
                        {recentSource.links.length ? (
                          <div style={{ marginTop: '1rem' }}>
                            <div style={{ display: 'inline-flex' }}>
                              {recentSource.links.map((src, k) => (
                                <>
                                  {src.label === 'PDF' && (
                                    <PdfButton
                                      key={k}
                                      hccId={hcc._id}
                                      id={encodeFactName(factName)}
                                      onClick={() => {
                                        dispatch(openPdfModal(recentSource));
                                      }}
                                      label={src.label}
                                    />
                                  )}
                                </>
                              ))}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ) : null}

                    {/* {sourceHistoryCount > 0 ? (
                        <SourceHistory sourceHistoryCount={sourceHistoryCount} factName={factName} hcc={hcc} />
                      ) : null} */}
                  </div>
                );
              })
            ) : (
              <div>No Sources found.</div>
            )}
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default IcdSourceCard;
