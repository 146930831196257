import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HccRecentSource } from '../../types/Hcc';

interface PdfModalState {
  isOpen: boolean;
  hccRecentSource: HccRecentSource | null;
}

const initialState: PdfModalState = {
  isOpen: false,
  hccRecentSource: null,
};

const modalSlice = createSlice({
  name: 'pdfModal',
  initialState,
  reducers: {
    openPdfModal: (state, action: PayloadAction<HccRecentSource>) => {
      state.isOpen = true;
      state.hccRecentSource = action.payload;
    },
    closePdfModal: (state) => {
      state.isOpen = false;
      state.hccRecentSource = null;
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.delete('hcc_id');
      currentUrl.searchParams.delete('fact_name');
      window.history.replaceState({}, '', currentUrl);
    },
  },
});

export const { openPdfModal, closePdfModal } = modalSlice.actions;

export default modalSlice.reducer;
