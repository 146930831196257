// @flow
import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode,
  className?: string,
  closeButton: ReactNode
};

const ModalBox = ({ children, closeButton, className = '' }: Props) => {
  return (
    <div className={`absolute w-full h-full max-w-screen-lg p-2 overflow-hidden -translate-x-1/2 -translate-y-1/2 bg-white outline-none left-1/2 top-1/2 rounded-2xl ${className}`}>
      <div className="w-full h-full overflow-auto">{children}</div>
      {closeButton}
    </div>
  );
};

export default ModalBox;
