/* eslint-disable react/display-name */
// @flow

import React, { ReactNode } from 'react';

export type Ref = HTMLDivElement;

type Props = {
  children: ReactNode
};
const ForwardRef = React.forwardRef<Ref, Props>((props, ref) => (
  <div {...props} ref={ref}>
    {props.children}
  </div>
));

export default ForwardRef;
