import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HccCode } from '../../types/Hcc';

interface InternalNoteModalState {
  isOpen: boolean;
  data: {
    hccId: string;
    icdCode: string;
    hcc: HccCode;
  } | null;
}

const initialState: InternalNoteModalState = {
  isOpen: false,
  data: null,
};

const modalSlice = createSlice({
  name: 'internalNoteModal',
  initialState,
  reducers: {
    openInternalNoteModal: (state, action: PayloadAction<{ hccId: string; icdCode: string; hcc: HccCode }>) => {
      state.isOpen = true;
      state.data = action.payload;
    },
    closeInternalNoteModal: (state) => {
      state.isOpen = false;
      state.data = null;
    },
  },
});

export const { openInternalNoteModal, closeInternalNoteModal } = modalSlice.actions;

export default modalSlice.reducer;
