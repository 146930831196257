export const log = (...args) => {
  if (process.env.REACT_APP_ENV !== 'production' || process.env.REACT_APP_DEBUG === 'true') {
    console.log(...args.map((a) => a || ''));
  }
};

export const sleep = (millis) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(), millis);
  });

/* eslint-disable max-len */
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const binaryToBlob = async (binaryData, contentType) => {
  let bytes = new Array(binaryData.length);

  for (let i = 0; i < binaryData.length; i++) {
    bytes[i] = binaryData.charCodeAt(i);
  }

  const data = new Uint8Array(bytes);

  return new Blob([data], { type: contentType });
};

export const calculateAvgRafUplift = (rafArr) => {
  if (!rafArr || !rafArr.length) return 0;

  const sumArr = (arr) => {
    let sum = 0;

    arr.forEach((raf) => {
      if (raf.rafScore) sum += raf.rafScore;
    });

    return sum;
  };

  let rafUplifts = [];

  rafArr.forEach((raf) => {
    const deltaRaf = sumArr(raf.deltaRafScore);

    rafUplifts.push(deltaRaf);
  });

  const avgRafUplift = (rafUplifts.reduce((pv, cv) => pv + cv, 0) / rafUplifts.length).toFixed(3);

  console.log('rafUplifts: ', rafUplifts);
  console.log('avgRafUplift: ', avgRafUplift);

  return avgRafUplift;
};

export const decodeEntity = (inputStr) => {
  let textarea = document.createElement('textarea');
  textarea.innerHTML = inputStr;
  return textarea.value;
};

export const getQueryParams = (queryString) => {
  const params = {};

  const queryStartIndex = queryString.indexOf('?');
  if (queryStartIndex !== -1) {
    const queryParamsString = queryString.slice(queryStartIndex + 1);
    const queryParams = queryParamsString.split('&');

    queryParams.forEach((param) => {
      const [key, value] = param.split('=');
      params[key] = value;
    });

    return params;
  }
};
